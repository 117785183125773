import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {path} from "../index";
import s from "../styles.module.css";
import {ReactComponent as Verified} from "../../../assets/svg/verified.svg";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/user.slice";
import {PATH} from "../../../constants/path";
import Button from "../../../common/ui_kit/button";
import classNames from "classnames";
import AndroidIcon from '@mui/icons-material/Android';
import apkFile from '../../../assets/apk/ITEMBUY.apk'

export default function HeaderBurgerLeft({openLeftMenu, toggleDrawer, type, handleLogout}) {
    const {user} = useSelector(selectUser)
    const iOS =
        typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    console.log(navigator.userAgent)
    const list = () => (
        <Box
            sx={{minWidth: 250, width: 'fit-content', minHeight: 'calc(100% - 52px)'}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {type === 1 && <List sx={{height: '100%'}}>
                <div onClick={(e) => e.stopPropagation()} style={{
                    padding: '8px 16px'
                }} className={s.avatar_box_select_content_header}>
                    <p className={s.user_name}>{`${user?.customer_profile?.surname || ''} ${user?.customer_profile?.firstname || ''}`}</p>
                    <Verified/>
                </div>

                {path.map((text, index) => (
                    <NavLink to={text.path} key={index}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary={text.title}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                ))}

                <div className={s.sub_items}>
                    <NavLink to={PATH.BLOGS}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary={'Блог'}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                    <NavLink to={PATH.SUPPORT}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary={'Помощь'}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                </div>

                <div className={s.btns_box}>
                    {!iOS && <a type={'download'} onClick={(e) => {
                        e.stopPropagation()
                    }} href={apkFile} className={s.download_btn}>
                        <AndroidIcon sx={{color: '#a5ca3a'}}/>
                        Скачать приложение
                    </a>}

                    <div className={s.exit} onClick={handleLogout}>
                        Выйти
                    </div>
                </div>
            </List>}
        </Box>

    );

    return (
        <React.Fragment>
            <SwipeableDrawer
                sx={{
                    top: '52px',

                    '& .MuiBackdrop-root': {
                        marginTop: '52px'
                    },
                    '& .MuiPaper-root': {
                        marginTop: '52px',
                        boxShadow: 'none'
                    },
                }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                anchor={type === 1 ? 'right' : 'left'}
                open={openLeftMenu}
                disableSwipeToOpen={true}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list('left')}
            </SwipeableDrawer>
        </React.Fragment>
    );
}
