export const validate = (values) => {
    const errors = {};

    if (!values.last_name) {
        errors.last_name = 'Обязательное поле';
    }
    if (!values.first_name) {
        errors.first_name = 'Обязательное поле';
    }
    if (!values.day || !values.month || !values.year) {
        errors.day = 'Заполните дату рождения';
    }
    if (!values.gender) {
        errors.gender = 'Обязательное поле';
    }
    if (!values.password) {
        errors.password = 'Обязательное поле';
    }
    if (values.password !== values.re_password) {
        errors.re_password = 'Пароли не совпадают';
    }
    if (!values.email) {
        errors.email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Неверный email адресс';
    }
    if (!values.number) {
        errors.number = 'Обязательное поле';
    } else if (values.number.length !== 18) {
        errors.number = 'Неверный номер телефона';
    }

    return errors;
}

