import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Input from "../../../ui_kit/inputs/input";
import TextArea from "../../../ui_kit/inputs/textarea";
import Button from "../../../ui_kit/button";
import {useFormik} from "formik";
import {ReactComponent as Close} from "../../../../assets/svg/close.svg";
import DescriptionFooterModal from "../../description_footer_modal";
import {validate} from "./validate";
import CustomSelect from "../../../ui_kit/inputs/select";
import {useSendSupportMessageMutation} from "../../../../redux/user.service";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectIsAuth} from "../../../../redux/slice/global.slice";
import {selectUser} from "../../../../redux/slice/user.slice";
import Header_modal from "../../../../components/header_modal";
import DescriptionSuccessInfo from "../../../description_succes_info";

const options = [
    {label: 'Регистрация', value: 2},
    {label: 'Участие в аукционе', value: 3},
    {label: 'Пополнение баланса', value: 4},
    {label: 'Оплата участия', value: 5},
    {label: 'Доставка', value: 6},
    {label: 'Возврат товара', value: 7},
    {label: 'Вывод средств', value: 8},
    {label: 'Ошибка на сайте', value: 9},
    {label: 'Предложения по улучшению сервиса', value: 1},
    {label: 'Другое', value: 10},
]

const ModalAskQuestion = ({onClose}) => {
    const [loading, setLoading] = useState(false)
    const [sendSupportMessage, {isLoading: isLoadingSupport}] = useSendSupportMessageMutation()
    const {isAuth} = useSelector(selectIsAuth)
    const {user} = useSelector(selectUser)

    const formik = useFormik({
        initialValues: {
            q_theme: null,
            q_text: '',
            email: '',
            name: ''
        },
        validate,
        onSubmit: (values) => {
            setLoading(true)
            sendSupportMessage({...values, q_theme: values.q_theme?.label})
                .unwrap()
                .then((res) => {
                    toast.success(res.detail || 'Сообщение отправлено')
                    onClose()
                })
                .catch(e => {
                    if (e?.data?.detail) {
                        toast.error(e?.data?.detail || 'Ошибка')
                    } else {
                        toast.error('Ошибка отправки, проверьте заполненость всех полей')
                    }
                    console.log(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    })

    useEffect(() => {
        if (isAuth) {
            formik.setFieldValue('name', user?.customer_profile?.firstname || '')
            formik.setFieldValue('email', user?.email || '')
        }
    }, [isAuth])
    return (
        <div className={s.content}>
            {/*<div className={s.header}>*/}
            {/*    <h3 className={s.title}>Задать вопрос</h3>*/}
            {/*    <div className={s.close} onClick={onClose}>*/}
            {/*        <Close/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Header_modal isMobile={false} onClose={onClose} title={'Задать вопрос'} typeModal={0}/>

            <form onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }} className={s.main_content}>
                <div className={s.action}>
                    <Input disabled={isAuth} value={formik.values.name}
                           errorMessage={formik.touched.name && formik.errors.name}
                           name={'name'} placeholder={'Введите имя'}
                           onBlur={formik.handleBlur}
                           onChange={formik.handleChange}/>

                    <CustomSelect placeholder={'Тема вопроса'}
                                  errorMessage={formik.touched.q_theme && formik.errors.q_theme}
                                  options={options}
                                  name={'q_theme'}
                                  value={formik.values.q_theme}
                                  onChange={(value) => {
                                      formik.setFieldValue('q_theme', value)
                                  }}/>
                    <TextArea value={formik.values.q_text}
                              errorMessage={formik.touched.q_text && formik.errors.q_text}
                              name={'q_text'} placeholder={'Задайте свой вопрос'}
                              onChange={formik.handleChange} onBlur={formik.handleBlur}/>

                    <Input disabled={isAuth} value={formik.values.email}
                           errorMessage={formik.touched.email && formik.errors.email}
                           name={'email'} placeholder={'Введите почту'} onBlur={formik.handleBlur}
                           onChange={formik.handleChange}/>


                </div>

                <div className={s.btn}>
                    <Button type={'submit'} disabled={loading}
                            style={{marginTop: '24px'}}>Отправить</Button>
                    <DescriptionSuccessInfo/>
                </div>


            </form>
        </div>
    );
};

export default ModalAskQuestion;
