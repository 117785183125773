export const PATH = {
    MAIN: '/',
    CONTACT: '/contact',
    REGUISITES: '/requisites',
    ACTIVE_AUCTIONS: 'active-auctions',
    DOCUMENTROOT: '/documents-roots',
    BLOGS: '/blog',
    SUPPORT: '/support',
    BLOGS_ITEM: '/blog/:id',
    SUPPORT_ITEM: '/support/:id',
    AUCTIONS: '/auctions',
    AUCTION: '/auction',
    AUCTIONS_TYPE: '/auctions/:type',
    AUCTIONS_TYPE_CATEGORY: '/auctions/:type/:category',
    AUCTIONS_TYPE_CATEGORY_ITEM: '/auctions/:type/:category/:category_item',
    AUCTION_TYPE_CATEGORY_ITEM_LOT: '/auction/:type/:category/:category_item/:lot_id',
    ACCOUNT: '/account',
    PROFILE: 'profile',
    WALLET: 'wallet',
    MY_LOTS: 'my-lots',
    FAVORITES: 'favorites',
    CALENDAR_EVENTS: 'calendar-events',
    VERIFICATION: 'verification',
    VERIFICATION_MYSELF: 'identity',
    VERIFICATION_ADDRESS: 'address',
    ORDER_ITEM: '/order-item/:id',
}
