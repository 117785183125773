import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQueryWithReauth} from "../api/baseQuery";
import Cookies from 'js-cookie';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['user', 'user23', 'payment'],
    endpoints: (build) => ({
        getUserInfo: build.mutation({
            query: () => ({
                url: `auth/user/get-user-profile/`,
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                },
                providesTags: ['user', 'user23']
            }),
            providesTags: ['user', 'user23']
        }),
        updateUserInfo: build.mutation({
            query: ({body, type = 'PATCH'}) => ({
                url: `auth/user/upd-profile/`,
                method: type,
                formData: true,
                body,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        updateEmail: build.mutation({
            query: ({body}) => ({
                url: `auth/user/upd-email/`,
                method: 'PUT',
                formData: true,
                body,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        updateNumber: build.mutation({
            query: ({body}) => ({
                url: `auth/user/upd-phone/send/`,
                method: 'PUT',
                formData: true,
                body,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        updateNumberSet: build.mutation({
            query: ({body}) => ({
                url: `auth/user/upd-phone/set/`,
                method: 'PUT',
                formData: true,
                body,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        updatePassword: build.mutation({
            query: ({body}) => ({
                url: `auth/user/upd-password/`,
                method: 'PUT',
                formData: true,
                body,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        verifyEmail: build.mutation({
            queryFn: async (payload, api, extraOptions, baseQuery) => {
                const csrfToken = await baseQuery({
                    url: `auth/csrf/`,
                    method: 'GET',
                })

                const token = csrfToken?.meta?.response?.headers.get('X-CSRFToken')
                Cookies.set('token', token);

                const verifEmail = await baseQuery({
                    url: `auth/verify-email/`,
                    method: 'POST',
                    body: {
                        token: payload
                    },
                    headers: {
                        'X-CSRFToken': token
                    }
                })

                return verifEmail
            },

            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        resendVerifyEmail: build.mutation({
            query: (token) => ({
                url: `auth/user/send-verification-email/`,
                method: 'POST',
                // body: {},
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['user']
        }),
        sendSupportMessage: build.mutation({
            query: (payload) => ({
                url: `mail/help/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        getCategorySupplier: build.query({
            query: () => ({
                url: `categories-for-suppliers/`,
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        sendSupplierMessage: build.mutation({
            query: (payload) => ({
                url: `mail/become-supplier/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        workUs: build.mutation({
            query: (payload) => ({
                url: `mail/work-with-us/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        makePayment: build.mutation({
            query: (payload) => ({
                url: `payments/customer/top-up/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        setCoupon: build.mutation({
            query: (payload) => ({
                url: `coupons/customer/redeem/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                },
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['payment']
        }),
        getPaymentTable: build.query({
            query: (payload) => ({
                url: `payments/customer/transactions/`,
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                },
                params: {
                    page_size: payload?.page_size,
                    page: payload?.page,
                    ord: payload?.ord
                }
            }),
            providesTags: ['payment']
        }),

    }),
})

export const {
    useGetUserInfoMutation,
    useSetCouponMutation,
    useMakePaymentMutation,
    useWorkUsMutation,
    useUpdatePasswordMutation,
    useUpdateNumberSetMutation,
    useUpdateEmailMutation,
    useUpdateNumberMutation,
    useUpdateUserInfoMutation,
    useVerifyEmailMutation,
    useSendSupportMessageMutation,
    useResendVerifyEmailMutation,
    useSendSupplierMessageMutation,
    useGetCategorySupplierQuery,
    useGetPaymentTableQuery,
} = userApi;
